import { LocalizationKeys, Translations } from "../types/localization";

export const defaultLocale = "en";
const supportedLocales: string[] = ["en", "fr", "es"];


// Load translations dynamically
export const loadTranslations = async (locale: string): Promise<Translations> => {
  try {
    const module = await import(`../locales/${locale}.json`);
    return module.default as Translations;
  } catch (error) {
    console.warn(`Failed to load translations for locale "${locale}". Falling back to default.`);
    return import(`../locales/${defaultLocale}.json`).then((module) => module.default as Translations);
  }
};

// Get the user's browser language
export const getBrowserLocale = (): string => {
  try {
    const browserLang = navigator.language.split('-')[0]; // Extract the language part (e.g., "en" from "en-US")
    return supportedLocales.includes(browserLang) ? browserLang : defaultLocale;
  } catch (error) {
    console.error('Error detecting browser locale:', error);
    return defaultLocale; // Fallback to default locale on error
  }
};

// Get a translation using a nested key
export const getTranslation = (
    translations: Translations,
    key: string | LocalizationKeys, // Allow both string and LocalizationKeys
    fallback = ""
  ): string => {
    // Convert the key to a string (useful for union types like LocalizationKeys)
    const keys = (typeof key === "string" ? key : String(key)).split(".");
    let value: string | Translations | undefined = translations;
  
    for (const k of keys) {
      if (typeof value === "object" && value !== null && k in value) {
        value = value[k];
      } else {
        console.warn(`Translation key "${key}" not found.`);
        return fallback || String(key); // Ensure fallback is a string
      }
    }
  
    return typeof value === "string" ? value : fallback || String(key);
  };